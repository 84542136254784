<template>
	<div class="form-group">

		<div class="form-group">
			<h4>{{ $t('cogView.densityAltitudeCalculator') }} <small>
				<help-button helpName="densityAltitude" :invertedColor="true" />
			</small></h4>
			<div class="row">
				<div class="col-12">
					<DataTable :value="values" responsiveLayout="scroll" currentPageReportTemplate="{totalRecords}"
						stateStorage="local" stateKey="dt-state-demo-local" class="noHeader">
						<Column>
							<template #body="slotProps">
								<TextInput :msgs="msgs" :readonly="true" :value="$t(slotProps.data.name)" class="form-control"
									style="min-width: 10rem;" :required="true" />
							</template>
						</Column>
						<Column>
							<template #body="slotProps">
								<div class="d-flex">
									<div class="d-block " style="flex: 1">
										<NumberInput :decimal="true" :minus="true" v-if="!slotProps.data.readonly"
											v-model.number="slotProps.data.value" :msgs="msgs" mode="decimal" @blur="onChange()"
											class="w-100 form-control" />
										<NumberInput :decimal="true" :minus="true" v-else :value="slotProps.data.value()" :msgs="msgs"
											mode="decimal" @blur="onChange()" :readonly="true" class="w-100 form-control" />
									</div>
									<div class="d-block">
										<select class="form-control h-100" style="width: 8rem" :value="slotProps.data.unit"
											:disabled="slotProps.data.units.length < 2"
											v-on:change="(e) => onUnitChange(slotProps.data, e.target.value)" @blur="onChange()" id="unit">
											<option v-for="unitKey in slotProps.data.units" v-bind:key="slotProps.data.key + unitKey"
												:value="unitKey">{{ unitKey | unitName }}</option>
										</select>
									</div>
								</div>
							</template>
						</Column>
						<template #header>
							<div class="row">
								<div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
									<label class="font-weight-bold">{{ $t('cogView.loadMetarFromAirport') }}: </label>
									<div class="d-flex">
										<div style="flex: 1">
											<TextInput v-if="inputError" :msgs="msgs" v-model="icaoCode" class="form-control is-invalid" />
											<TextInput v-else :msgs="msgs" v-model="icaoCode" class="form-control" />
										</div>
										<div class="d-none d-sm-block">
											<Button class="btn btn-primary w-100 ml-2" v-on:click="loadMetar()" :disabled="loading">
												<span v-if="!loading">{{ $t('cogView.loadMetar') }}</span>
												<font-awesome-icon icon="sync" v-if="loading" class="btn-loading-icon m-0" />
											</Button>
										</div>
									</div>
									<span class="d-block invalid-feedback">{{ inputError }}</span>
									<div class="d-block d-sm-none">
										<Button class="btn btn-primary w-100 " v-on:click="loadMetar()" :disabled="loading">
											<span v-if="!loading">{{ $t('cogView.loadMetar') }}</span>
											<font-awesome-icon icon="sync" v-if="loading" class="btn-loading-icon m-0" />
										</Button>
									</div>
								</div>
							</div>
							<div class="text-center mt-2" v-if="weather">
								<small v-if="((weather.airport || {}).distance || {}).value"
									v-html="$t('weatherView.distanceFromAirport', { name: weather.airport.distance.from.name, code: weather.airport.distance.from.code, distance: Math.round(weather.airport.distance.value) })"></small>
								<h6 class="m-0">
									{{ weather.metar.airport.name }} ({{ weather.metar.airport.icao }})
								</h6>
							</div>
						</template>
						<template #footer>
							<h3 class="mt-2 text-center font-weight-bold">{{ $t('cogView.result') }}:</h3>
							<div class="row mt-3">
								<div class="col-12 col-md-6 offset-md-3">
									<label class="">{{ $t('cogView.densityAltitude') }}: </label>
									<div class="d-flex">
										<div style="flex: 1">
											<InputNumber :readonly="true"
												:value="calculateDensityAltitude() | convert('mb', densityAltitudeUnit)"
												mode="decimal" class="w-100" inputStyle="text-decoration: underline;font-size: 1.5rem;"
												inputClass="w-100 bg-light border-0 font-weight-bold px-0" :minFractionDigits="2"
												:maxFractionDigits="2" @blur="onChange()"
												:suffix="' ' + densityAltitudeUnit | unitSuffix" />
										</div>
										<div class="d-block">
											<select class=" form-control h-100" style="min-width: 5rem;" v-model="densityAltitudeUnit"
												@blur="onChange()" id="unit">

												<option v-for="unit in units.filter(u => ['m', 'in', 'ft', 'yd', 'mi', 'km'].includes(u.key))"
													v-bind:key="'densityAltitudeUnit' + unit.key" :value="unit.key">{{ $t(unit.name) }}</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<SaveToBriefingButton :added="addedToBriefing" @click="addToBriefing()" type="densityAltitude" />
						</template>
					</DataTable>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import briefingmanager from '@/plugins/briefingmanager';
import NumberInput from "./inputs/NumberInput.vue";
import SaveToBriefingButton from "./SaveToBriefingButton.vue";
import HelpButton from './help/HelpButton.vue';

export default {
	name: "DensityAltitudeCalculatorForm",
	props: {
		msgs: Object,
	},
	components: { SaveToBriefingButton, NumberInput, HelpButton },
	emits: [],
	data() {
		return {
			values: [],
			icaoCode: "",
			densityAltitudeUnit: 'ft',
			inputError: null,
			weather: null,
			addedToBriefing: false,
			loading: false,
		}
	},
	computed: {
		units() {
			return Object.values(this.$units.all);
		}
	},
	mounted() {
		this.values = [
			{
				name: 'cogView.temp',
				key: "temp",
				value: 0,
				unit: 'c',
				units: ["c", "f", "k"],
			},
			{
				name: 'cogView.dewpoint',
				key: "dewpoint",
				value: 0,
				unit: 'c',
				units: ["c", "f", "k"],
			},
			{
				name: 'cogView.relativeHumidity',
				key: "relativeHumidity",
				value: () => this.calcRelativeHumidity(),
				readonly: true,
				max: 0,
				min: 0,
				unit: '%',
				units: ["%"]
			},
			{
				name: 'cogView.altimeter',
				key: "altimeter",
				value: 0,
				unit: 'mb',
				units: ["mb", "hPa"]
			},
			{
				name: 'cogView.elevation',
				key: "elevation",
				value: 0,
				unit: 'ft',
				units: ["m", "in", "ft", "yd", "mi", "km"]
			},
		];



		this.densityAltitudeUnit = window.consentedStorage.getItem('densityAltitudeUnit') || 'ft'
	},
	methods: {
		findUnit(key) {
			return this.$units.getUnit(key) || {};
		},
		onUnitChange(value, newUnit) {
			if (value == null) return;
			if (newUnit == null) return;
			this.values = this.values.map(v => {
				if (v.key == value.key) {
					const oldUnit = this.findUnit(value.unit);
					const unit = this.findUnit(newUnit);
					v.value = unit.fromTrueValue(oldUnit.trueValue(value.value));
					v.unit = unit.key;
				}
				return v;
			})
		},
		calcRelativeHumidity() {
			const T = this.getTrueValueOfKey('temp');
			const Dp = this.getTrueValueOfKey('dewpoint');
			const B = 17.625;
			const L = 243.04;

			return Math.round(10000 * ((Math.pow(Math.E, (B * Dp) / (L + Dp))) / (Math.pow(Math.E, (B * T) / (L + T))))) / 100;
		},
		calcTrueValue(value) {
			if (value == null) return 0;
			if (value.value == null) return 0;
			if (value.unit == null) return 0;
			const unit = this.findUnit(value.unit);
			if (unit == null) return 0;
			return unit.trueValue(value.value);
		},
		getTrueValueOfKey(key) {
			if (key == null) return 0;
			const value = this.values.find(v => v.key === key);
			if (value == null) return 0;
			return this.calcTrueValue(value);
		},
		calculateAirDensity() {
			const AS = this.getTrueValueOfKey('altimeter'); //Altimeter setting
			const h = this.getTrueValueOfKey('elevation'); //Weather station elevation
			const P = Math.pow(Math.pow(AS, 0.190263) - (8.417286 * Math.pow(10, -5) * h), 1 / 0.190263); //Air Pressure



			const Rv = 461.495; //Specific gas constant for water vapor
			const Rd = 287.058; //Specific gas constant for dry air
			const T = this.getTrueValueOfKey('temp'); //Temperature
			const RH = this.calcRelativeHumidity(); //Relative humidity
			const Pv = (RH / 100) * 6.1078 * Math.pow(10, 7.5 * T / (T + 237.3)); //Water vapor pressure
			const Pd = P - Pv; //Pressure of dry air in Pascals


			let p = ((Pd * 100) / (Rd * (T + 273.15))) + ((Pv * 100) / (Rv * (T + 273.15))); //(Pd / (Rd * T)) + (Pv / (Rv * T))
			return p;
		},
		calculateDensityAltitude() {
			const p = this.calculateAirDensity();
			const H = 44.3308 - (42.2665 * Math.pow(p, 0.234969)); //44.3308 - 42.2665 * ρ^0.234969
			return (H * 1000) || 0;
		},
		async loadMetar() {
			this.loading = true;
			if (this.icaoCode === "") {
				this.inputError = this.$t('weatherView.icao-empty')
				return;
			}
			const data = await this.$rest.getWeatherWithICAO(this.icaoCode);
			this.loading = false;
			if(data == null) {
				this.inputError = this.$t('weatherView.icao-not-found')
				return;
			}

			this.weather = data;
			this.inputError = null;
		},
		addToBriefing() {
			this.addedToBriefing = true;
			let title = "";
			if (this.weather) {
				title = ` <div class="d-flex w-100 flex-column justify-content-center align-items-center">
            ${(((this.weather.airport || {}).distance || {}).value ? (`
             <h4 style="text-align: center">
              #{weatherView.distanceFrom} <strong>${this.weather.distance.from.name}(${this.weather.distance.from.code})</strong> #{weatherView.fromAirport}: <strong>${Math.round(this.weather.distance.value)} km</strong>
            </h4>`) : (''))}
            <h2 class="m-0 text-center">
              ${this.weather.metar.airport.name} (${this.weather.metar.airport.icao})
            </h2>
          </div>`;
			}


			briefingmanager.addSubGroup("densityAltitude", {
				key: `${this.icaoCode || Math.random()}`,
				title: `<h2 style="margin: 0; text-align: center;">${title}</h2>`,
				localTitle: `${this.weather.metar.airport.name} (${this.weather.metar.airport.icao})`,
				tables: [
					{
						key: "full",
						rows: [
							...this.values.map(v => {
								return [
									{
										style: "flex: 1; text-align: center; font-weight: bold;",
										value: `#{${v.name}}`,
									},
									{
										style: "flex: 1; text-align: center;",
										value: `${(v.readonly ? v.value() : v.value).toFixed(2)}${(this.findUnit(v.unit).suffix || v.unit)}`,
									}
								]
							}),
							[
								{
									style: "border-top: solid 3px black; flex: 1; text-align: center; font-weight: bold;",
									value: "#{cogView.densityAltitude}",
								}
							],
							[
								{
									style: "flex: 1; text-align: center;",
									value: `${this.$units.convert(this.calculateDensityAltitude(), 'mb', this.densityAltitudeUnit, 0)} ${this.findUnit(this.densityAltitudeUnit).suffix}`,
								}
							]
						]
					}
				]
			});
		},
		onChange() {
			this.addedToBriefing = false;
		}
	},
	watch: {
		densityAltitudeUnit() {
			window.consentedStorage.setItem('densityAltitudeUnit', this.densityAltitudeUnit)
		},
		weather() {
			if (this.weather == null) return;
			this.onChange();
			this.values = this.values.map(v => {
				if (v.key == 'temp') {
					v.unit = 'c';
					v.value = this.weather.metar.temperature;
				}
				if (v.key == 'dewpoint') {
					v.unit = 'c';
					v.value = this.weather.metar.dewPoint;
				}
				if (v.key == 'altimeter') {
					v.unit = 'mb';
					v.value = this.weather.metar.altimeter;
				}
				if (v.key == 'elevation') {
					v.unit = 'ft';
					v.value = this.weather.metar.airport.altitude;
				}
				return v;
			})
		}
	}
}
</script>

<style >
.noHeader .p-datatable-thead {
	display: none;
}
</style>
