<template>

	<div>
		<div class="row">
			<div class="col-md-12 mt-5">
        <CogCalculatorForm
          :msgs="msgs"
          class="px-3"
        />
			</div>
		</div>

    <div class="row">
			<div class="col-md-12 mt-5 pt-5">
        <DensityAltitudeCalculatorForm
          :msgs="msgs"
          class="px-3"
        />
			</div>
		</div>
	</div>
</template>

<script>
import {Fade} from "@egjs/flicking-plugins";
import CogCalculatorForm from "@/components/cog/CogCalculatorForm.vue";
import DensityAltitudeCalculatorForm from "@/components/DensityAltitudeCalculatorForm.vue";

export default {
	name: 'CogView',
	components: {CogCalculatorForm, DensityAltitudeCalculatorForm},
	computed: {
		cancelBtnTitle() {
			return this.$t('planes.cancelButton')
		}
	},

	data() {
		return {
			plugins: [new Fade()],
			selectedPlane: null,
			planes: [],
			activetab: '2',
		}
	},
	watch: {
		selectedPlane: function (newVal, oldVal) {
			window.newEvent("COG", {
				planeId: newVal ? newVal.id : null,
			})
		}
	},
	props: {
		msgs: Object
	},
	methods: {
	},
}

</script>

<style>


</style>
